<template>
  <div class="headliner">
    <div class="container">
      <h2>Хедлайнеры, которые выступали у нас</h2>
    </div>
    <div class="headliner__content">
      <div class="headliner__img">
        <img :src="headliner?.main_image" :alt="headliner?.title" />
        <div class="headliner__title">{{ headliner?.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShiftHeadliner",
  props: {
    headliner: [Array, Object],
  },
};
</script>

<style lang="scss">
.headliner {
  margin-top: 80px;
  h2 {
    margin-bottom: 30px;
  }
  &__content {
  }
  &__img {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  &__title {
    color: var(--white);
    text-transform: uppercase;
    font-size: 162px;
    line-height: 200px;
    font-weight: 700;
    position: absolute;
    z-index: 1;
    text-align: center;
  }
  @include adaptive(tablet-big) {
    &__title {
      font-size: 80px;
      line-height: 100px;
    }
  }

  @include adaptive(phone) {
    &__title {
      font-size: 20px;
      line-height: 27px;
    }
    &__img {
      height: 400px;
      img {
        object-position: left;
        object-fit: cover;
      }
    }
  }
}
</style>
