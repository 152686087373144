<template>
  <div class="shift">
    <ShiftTitle :formatted-shift="shift" />
    <ShiftTracks :tracks="shift.info_directions?.data" />
    <ShiftTeams />
    <ShiftSelection />
    <ShiftsImages :images="shift.info_photo_gallery" />
    <ShiftVideos :title="'Видео с форума'" :videos="shift.info_video?.data" />
    <ShiftSpeakers :speakers="shift.info_speaker?.data" />
    <ShiftHeadliner :headliner="headliner" />
    <!--    <HomeMaterials />-->
    <HomeFAQ :is-shift-active="shift.is_access_new_statement" />
  </div>
</template>

<script>
import ShiftTitle from "@/components/ShiftSections/ShiftTitle";
import ShiftTracks from "@/components/ShiftSections/ShiftTracks";
import ShiftTeams from "@/components/ShiftSections/ShiftTeams";
import ShiftSelection from "@/components/ShiftSections/ShiftSelection";
import ShiftsImages from "@/components/ShiftSections/ShiftImages";
import ShiftVideos from "@/components/ShiftSections/ShiftVideos";
import ShiftSpeakers from "@/components/ShiftSections/ShiftSpeakers";
import HomeMaterials from "@/components/HomeSections/HomeMaterials";
import HomeFAQ from "@/components/HomeSections/HomeFAQ";
import ShiftHeadliner from "@/components/ShiftSections/ShiftHeadliner";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "Shift",
  components: {
    ShiftHeadliner,
    HomeFAQ,
    HomeMaterials,
    ShiftSpeakers,
    ShiftVideos,
    ShiftsImages,
    ShiftSelection,
    ShiftTeams,
    ShiftTracks,
    ShiftTitle,
  },
  data() {
    return {
      shift: {},
      timeout: null,
    };
  },
  computed: {
    info() {
      return JSON.parse(
        JSON.stringify(this.$store.state.storage.storageShiftsData)
      );
    },
    currentShiftId() {
      return this.info
        .map((item, index) => {
          let slug = "first";
          if (index === 1) {
            slug = "second";
          }
          if (index === 2) {
            slug = "third";
          }

          item.slug = slug;
          return item;
        })
        .find((item) => {
          return item.slug === this.$route.params.slug;
        }).id;
    },
    headliner() {
      return this.shift.info_headliners?.data.find((item) => {
        return item.category_key === "headliner";
      });
    },
  },
  methods: {
    async shiftRequest() {
      if (
        JSON.stringify(
          this.$store.state.storage[
            `storageShiftsOneData${this.$route.params.slug}`
          ]
        ) === "{}"
      ) {
        await createRequest(requestConfigs.GETShift, {
          routerPayload: { id: this.currentShiftId },
        }).then((result) => {
          this.$store.commit("setShiftsOneData", [
            result.data,
            `storageShiftsOneData${this.$route.params.slug}`,
          ]);
          this.shift =
            this.$store.state.storage[
              `storageShiftsOneData${this.$route.params.slug}`
            ];
        });
      } else
        this.shift =
          this.$store.state.storage[
            `storageShiftsOneData${this.$route.params.slug}`
          ];
    },
    setSeo() {
      document
        .querySelector('head meta[name="description"]')
        .setAttribute("content", this.shift?.seo_description);
      document
        .querySelector('head meta[property="og:description"]')
        .setAttribute("content", this.shift?.seo_description);
      document.title = this.shift?.seo_title;
      document
        .querySelector('head meta[property="og:title"]')
        .setAttribute("content", this.shift?.seo_title);
      document
        .querySelector('head meta[property="og:url"]')
        .setAttribute(
          "content",
          `https://liga-forum.ru/shift/${this.$route.params.slug}`
        );
    },
  },
  watch: {
    "$route.params.slug"() {
      if (this.$route.params.slug) {
        this.shiftRequest();
        this.setSeo();
      }
    },
  },
  async beforeMount() {
    await this.shiftRequest();
    this.setSeo();
  },
};
</script>

<style lang="scss">
.teams {
  margin-top: 80px;
  &__content {
    h2 {
      margin-bottom: 30px;
    }
  }
}
</style>
