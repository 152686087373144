<template>
  <div class="section-title">
    <div id="video-bg" class="video-bg">
      <video
        width="100%"
        preload="auto"
        autoplay="autoplay"
        muted=""
        loop="loop"
        :poster="formattedShift.image_background_url"
      ></video>
    </div>
    <div class="shift__main">
      <div class="container">
        <div class="shift__content">
          <div class="shift__number">Cмена {{ formattedShift.number }}</div>
          <div class="shift__name">{{ formattedShift.title }}</div>
          <div class="shift__date">{{ timeStart }} - {{ timeEnd }}</div>
          <div class="shift__desc">
            {{ formattedShift.description }}
          </div>
          <div class="shift__request">
            <a
              href="https://lk.ligastudentov.com/"
              class="btn btn--red"
              target="_blank"
            >
              ПОДАТЬ ЗАЯВКУ
            </a>
          </div>

          <!--          <Button-->
          <!--            red-->
          <!--            @click="toLk"-->
          <!--            :disabled="!formattedShift.is_access_new_statement"-->
          <!--          >-->
          <!--            <span v-if="formattedShift.is_access_new_statement">-->
          <!--              ПОДАТЬ ЗАЯВКУ-->
          <!--            </span>-->
          <!--            <span v-else>ПОДАЧА ЗАКРЫТА</span>-->
          <!--          </Button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import moment from "moment";
export default {
  name: "ShiftTitle",
  components: { Button },
  computed: {
    timeStart() {
      return moment(this.formattedShift.beginning_at).format("DD");
    },
    timeEnd() {
      return moment(this.formattedShift.ended_at_format, "DD.MM.YYYY")
        .locale("ru")
        .format("DD MMMM");
    },
  },
  props: {
    formattedShift: [Array, Object],
  },
  methods: {
    toLk() {
      if (this.$store.state.user.isAuthenticated === false) {
        this.$store.commit("setCurrentPopup", {
          name: "AuthPopup",
          isShown: true,
          props: {
            popupClass: "auth-popup",
          },
        });
      } else {
        this.$router.push({ name: "LkAbout" });
      }
    },
  },
};
</script>

<style lang="scss">
.shift {
  &__main {
    position: relative;
    color: var(--white);
    margin-top: 170px;
  }
  &__number {
    @include text20();
    margin-bottom: 30px;
  }
  &__name {
    font-size: 50px;
    line-height: 75px;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  &__date {
    @include text20();
    font-weight: 400;
    margin-bottom: 60px;
  }
  &__desc {
    margin-bottom: 80px;
    max-width: 600px;
  }
  &__request {
    display: flex;
    position: relative;
    z-index: 1;
  }
  @include adaptive(tablet-small) {
    .section-title {
      padding: 50px 0;
    }
    &__main {
      margin-top: 60px;
    }
    &__name {
      font-size: 20px;
      line-height: 27px;
    }
    &__desc {
      margin-bottom: 30px;
    }
  }
  @include adaptive(phone) {
    &__name {
      font-size: 20px;
      line-height: 27px;
    }
  }
}
</style>
