<template>
  <div class="selection">
    <div class="container">
      <div class="selection__content">
        <h2>Процедура отбора</h2>
        <div class="selection__list">
          <div
            class="selection-item"
            v-for="(item, index) in selectionArray"
            :key="index"
          >
            <div class="selection-item__number">{{ index + 1 }}</div>
            <div class="selection-item__step">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShiftSelection",
  data() {
    return {
      selectionArray: [
        { text: "Зарегистрироваться на официальном сайте «ЛИГА ФОРУМ»" },
        { text: "Выбрать смену" },
        { text: "Заполнить анкету" },
        { text: "Дождаться оценки оргкомитета" },
        { text: "Дождаться результатов рассмотрения заявки в личном кабинете" },
      ],
    };
  },
};
</script>

<style lang="scss">
.selection {
  margin-top: 80px;
  &__content {
    h2 {
      margin-bottom: 30px;
    }
  }
  &__list {
    display: flex;
    align-items: stretch;
    gap: 35px 5px;
    flex-wrap: wrap;
    justify-content: center;
  }
  &-item {
    display: flex;
    align-items: center;
    &__number {
      font-size: 120px;
      line-height: 1;
      color: var(--btn-red-color1);
      font-weight: 700;
      margin-right: 15px;
    }
    &__step {
      max-width: 190px;
      margin-bottom: 12px;
    }
  }
  @include adaptive(tablet-small) {
    &__list {
      flex-direction: column;
      align-items: center;
    }
    &-item {
      position: relative;
      &::after {
        content: "";
        background: url("~@/assets/img/next-step.svg") no-repeat;
        background-size: contain;
        display: block;
        width: 70px;
        height: 70px;
        position: absolute;
        top: 100%;
      }
      &:nth-child(odd) {
        align-self: flex-start;
      }
      &:nth-child(even) {
        align-self: flex-end;
        &::after {
          right: 15px;
          transform: scaleX(-1);
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  @include adaptive(phone) {
    &-item {
      &__number {
        font-size: 60px;
        line-height: 90px;
      }
      &::after {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>
