// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/next-step.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".selection{margin-top:80px}.selection__content h2{margin-bottom:30px}.selection__list{display:flex;align-items:stretch;gap:35px 5px;flex-wrap:wrap;justify-content:center}.selection-item{display:flex;align-items:center}.selection-item__number{font-size:120px;line-height:1;color:var(--btn-red-color1);font-weight:700;margin-right:15px}.selection-item__step{max-width:190px;margin-bottom:12px}@media screen and (max-width:767px){.selection__list{flex-direction:column;align-items:center}.selection-item{position:relative}.selection-item:after{content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain;display:block;width:70px;height:70px;position:absolute;top:100%}.selection-item:nth-child(odd){align-self:flex-start}.selection-item:nth-child(2n){align-self:flex-end}.selection-item:nth-child(2n):after{right:15px;transform:scaleX(-1)}.selection-item:last-child:after{display:none}}@media screen and (max-width:479px){.selection-item__number{font-size:60px;line-height:90px}.selection-item:after{width:40px;height:40px}}", ""]);
// Exports
module.exports = exports;
