<template>
  <div class="materials">
    <div class="container">
      <div class="materials__content">
        <div class="materials-item">
          <div class="materials-item__title">Материалы</div>
          <div class="materials-item__links">
            <a
              :href="item.url"
              target="_blank"
              v-for="(item, index) in materials"
              :key="index"
            >
              {{ item.title }}
            </a>
          </div>
        </div>
        <div class="materials-item">
          <div class="materials-item__title">Стикеры</div>
          <!--          <div class="materials-item__links">-->
          <!--            <a-->
          <!--              :href="item.url"-->
          <!--              target="_blank"-->
          <!--              v-for="(item, index) in stickers"-->
          <!--              :key="index"-->
          <!--            >-->
          <!--              {{ item.title }}-->
          <!--            </a>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "HomeMaterials",
  data() {
    return {
      materials: [],
      stickers: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageMaterialsData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "materials",
        },
      }).then((result) => {
        this.$store.commit("setMaterialsData", result.data);
        this.materials = this.$store.state.storage.storageMaterialsData;
      });
    } else this.materials = this.$store.state.storage.storageMaterialsData;
    // if (!this.$store.state.storage.storageStickersData.length) {
    //   createRequest(requestConfigs.GETInfoBlock, {
    //     queryPayload: {
    //       key_block: "stickers",
    //     },
    //   }).then((result) => {
    //     this.$store.commit("setStickersData", result.data);
    //     this.stickers = this.$store.state.storage.storageStickersData;
    //   });
    // } else this.stickers = this.$store.state.storage.storageStickersData;
  },
};
</script>

<style lang="scss">
.materials {
  margin-top: 80px;
  &__content {
    background: url("~@/assets/img/materials-bg.webp") no-repeat center;
    background-size: cover;
    padding: 70px 220px;
    border-radius: 15px;
    position: relative;
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 50px;
    color: var(--white);
    overflow: hidden;
    &::before {
      content: "";
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    &__title {
      @include text30();
      margin-bottom: 55px;
    }
    &__links {
      display: flex;
      flex-direction: column;
      gap: 10px;
      a {
        display: block;
        @include text20();
        color: var(--white);
        filter: opacity(90%);
        border-bottom: 1px solid var(--white);
        max-width: fit-content;
        &:hover {
          -webkit-text-stroke-width: 0.5px;
        }
      }
    }
  }
  @include adaptive(desktop-small) {
    &__content {
      padding: 40px;
    }
  }
  @include adaptive(tablet-small) {
    &__content {
      &::before {
        display: none;
      }
      display: flex;
      flex-direction: column;
      background: unset;
      padding: unset;
      border-radius: unset;
      gap: 15px;
    }
    &-item {
      color: var(--black);
      &__title {
        margin-bottom: 15px;
      }
      &__links {
        a {
          color: var(--black);
          font-weight: 400;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
