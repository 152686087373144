<template>
  <div class="teams">
    <div class="container">
      <div class="teams__content">
        <h2>Команды смены</h2>
        <div class="teams__list">
          <div
            class="teams-item"
            v-for="(item, index) in info"
            :key="index"
            @click="openPopup(item, item.info_photo_gallery)"
          >
            <div class="teams-item__img">
              <img :src="item.logo" :alt="item.title" />
            </div>
            <div class="teams-item__title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "ShiftTeams",
  data() {
    return {
      info: [],
    };
  },
  methods: {
    openPopup(item, gallery) {
      this.$store.commit("setCurrentPopup", {
        name: "TeamPopup",
        isShown: true,
        props: {
          info: item,
          popupClass: "team-popup",
          gallery: gallery,
        },
      });
    },
  },
  beforeMount() {
    if (!this.$store.state.storage.storageTeamsData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "teams",
        },
      }).then((result) => {
        this.$store.commit("setTeamsData", result.data);
        this.info = this.$store.state.storage.storageTeamsData;
      });
    } else this.info = this.$store.state.storage.storageTeamsData;
  },
};
</script>

<style lang="scss"></style>
