<template>
  <div class="videos">
    <div class="videos__content">
      <div class="container">
        <h2>{{ title }}</h2>
      </div>
      <Carousel
        class="videos__list"
        :breakpoints="breakpoints"
        :settings="settings"
        v-if="videos && videos.length !== 0"
      >
        <Slide v-for="(item, index) in videos" :key="index">
          <div class="videos__block">
            <Video :video="item" />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import Video from "@/components/Blocks/Video";
import { Carousel, Slide } from "vue3-carousel";
export default {
  name: "ShiftVideos",
  components: { Video, Carousel, Slide },
  data() {
    return {
      settings: {
        snapAlign: "center",
        wrapAround: true,
        itemsToScroll: 1,
        itemsToShow: 1.35,
        autoplay: 4000,
      },
      breakpoints: {
        1921: {
          itemsToShow: 5.2,
        },
        1500: {
          itemsToShow: 3.5,
        },
        1400: {
          itemsToShow: 2.6,
        },
        959: {
          itemsToShow: 2.5,
        },
        767: {
          itemsToShow: 1.8,
        },
        481: {
          itemsToShow: 2.2,
        },
        321: {
          itemsToShow: 1.5,
        },
      },
    };
  },
  props: {
    title: String,
    videos: Array,
  },
};
</script>

<style lang="scss"></style>
