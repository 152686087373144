<template>
  <div class="speakers">
    <div class="speakers__content">
      <div class="container">
        <h2>Спикеры, которые выступали у нас</h2>
      </div>
      <Carousel
        class="speakers__list"
        :breakpoints="breakpoints"
        :settings="settings"
        v-if="speakers && speakers.length !== 0"
      >
        <Slide
          class="speakers-item"
          v-for="(item, index) in speakers"
          :key="index"
        >
          <div class="speakers-item__block">
            <div class="speakers-item__img">
              <img :src="item.main_image" :alt="item.mini_description" />
            </div>
            <div class="speakers-item__content">
              <div class="speakers-item__info">
                <div class="speakers-item__name">
                  {{ item.first_name }} {{ item.last_name }}
                </div>
                <div class="speakers-item__profession">
                  {{ item.mini_description }}
                </div>
              </div>
              <div class="speakers-item__desc">
                {{ item.description }}
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
export default {
  name: "ShiftSpeakers",
  components: { Carousel, Slide },
  data() {
    return {
      settings: {
        snapAlign: "center",
        wrapAround: true,
        itemsToScroll: 1,
        itemsToShow: 1.3,
        autoplay: 5000,
      },
      breakpoints: {
        1921: {
          itemsToShow: 4.5,
        },
        1500: {
          itemsToShow: 3.5,
        },
        1400: {
          itemsToShow: 3.2,
        },
        959: {
          itemsToShow: 2.5,
        },
        767: {
          itemsToShow: 2.1,
        },
        481: {
          itemsToShow: 3,
        },
        321: {
          itemsToShow: 1.7,
        },
      },
    };
  },
  props: {
    speakers: Array,
  },
};
</script>

<style lang="scss">
.speakers {
  margin-top: 80px;
  &__content {
    h2 {
      margin-bottom: 30px;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    gap: 50px;
    .carousel {
      &__viewport {
        width: 100%;
      }
      &__track {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
      }
      &__slide {
        padding: 0 25px;
      }
    }
  }
  &-item {
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    height: 750px;
    &__block {
      padding: 60px 40px;
      height: 100%;
      position: relative;

      border-radius: 15px;
      width: 100%;
      overflow: hidden;
    }
    &:hover {
      .speakers-item__content {
        transform: translateY(0);
      }
      .speakers-item__desc {
        visibility: unset;
        opacity: 1;
      }
      .speakers-item__img {
        &::after {
          visibility: unset;
          opacity: 1;
        }
      }
      .speakers-item__profession {
        display: unset;
      }
    }
    &__img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6));
      }
      &::after {
        content: "";
        display: block;
        visibility: hidden;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.5s ease-in-out;
        background-image: linear-gradient(
          0deg,
          rgba(229, 30, 87, 0.5),
          rgba(229, 30, 87, 0.5)
        );
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }
    &__content {
      position: relative;
      color: var(--white);
      height: 100%;
      transform: translateY(calc(100% - 60px));
      transition: all 0.5s ease-in-out;
    }
    &__info {
    }
    &__name {
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    &__profession {
      font-size: 20px;
      line-height: 24px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    &__desc {
      visibility: hidden;
      opacity: 0;
      margin-top: 80px;
    }
  }
  @include adaptive(tablet-small) {
    &__list {
      .carousel__track {
      }
      .carousel__slide {
        padding: 0 7.5px;
      }
    }
    &-item {
      height: 365px;
      min-width: unset;

      &__block {
        padding: 15px;
      }
      &__content {
        transform: translateY(calc(100% - 70px));
      }
      &__name {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
      }
      &__profession {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  @include adaptive(tablet-small) {
    &__list {
    }
  }
}
</style>
