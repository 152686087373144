<template>
  <div class="images">
    <div class="images__content">
      <Carousel
        class="images__list images__content--top"
        :breakpoints="breakpoints"
        :settings="settings"
        v-if="images && images.length !== 0"
      >
        <Slide
          class="images-item"
          v-for="(item, index) in images"
          :key="index"
          @click="openPopup(item.url)"
        >
          <div class="images-item__block">
            <img :src="item.url" alt="" />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "ShiftImages",
  components: { Carousel, Slide },
  data() {
    return {
      settings: {
        snapAlign: "center",
        wrapAround: true,
        itemsToScroll: 1,
        itemsToShow: 1.3,
        autoplay: 5000,
        mouseDrag: true,
        touchDrag: true,
      },
      breakpoints: {
        1921: {
          itemsToShow: 5.2,
        },
        1500: {
          itemsToShow: 3.5,
        },
        1400: {
          itemsToShow: 2.6,
        },
        959: {
          itemsToShow: 2.5,
        },
        767: {
          itemsToShow: 2.7,
        },
        481: {
          itemsToShow: 2.35,
        },
        321: {
          itemsToShow: 1.6,
        },
      },
    };
  },
  methods: {
    openPopup(item) {
      this.$store.commit("setCurrentPopup", {
        name: "ImagePopup",
        isShown: true,
        props: {
          popupClass: "image-popup",
          image: item,
        },
      });
    },
  },
  props: {
    images: Array,
  },
};
</script>

<style lang="scss">
.images {
  margin-top: 80px;
  &__content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 70px;
    &--top {
      margin-left: -300px;
    }
    &--bottom {
      margin-left: -100px;
    }
  }
  &__list {
    display: flex;
    gap: 85px;
    .carousel {
      &__viewport {
      }
      &__track {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
      }
      &__slide {
      }
    }
  }
  &-item {
    max-width: 700px;
    width: 100%;
    height: 325px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
    padding: 0 25px;
    &__block {
      height: 100%;
      border-radius: 15px;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
  @include adaptive(tablet-big) {
    &__list {
      .carousel {
        &__track {
        }
      }
    }
    &-item {
      height: 200px;
      min-width: unset;
      max-width: unset;
      padding: 0 7.5px;
    }
  }
  @include adaptive(phone) {
    &-item {
      height: 150px;
    }
  }
}
</style>
