<template>
  <div class="tracks">
    <div class="container">
      <div class="tracks__content">
        <h2>Образовательные треки</h2>
        <div class="tracks__list">
          <div
            class="tracks-item"
            v-for="(item, index) in tracks"
            :key="index"
            @click="openPopup(item)"
          >
            <img :src="item.icon" alt="" />
            <div class="tracks-item__title">{{ item.title }}</div>
            <div class="tracks-hint">
              <div class="tracks-hint__title">
                {{ item.title }}
              </div>
              <div class="tracks-hint__text">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShiftTracks",
  data() {
    return {
      width: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.width < 959;
    },
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
      ``;
    },
    openPopup(item) {
      if (this.isMobile) {
        this.$store.commit("setCurrentPopup", {
          name: "TrackPopup",
          isShown: true,
          props: {
            popupClass: "track-popup",
            item: item,
          },
        });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
  props: {
    tracks: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.tracks {
  margin-top: 60px;
  &__content {
    h2 {
      margin-bottom: 30px;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 55px;
    flex-wrap: wrap;
    justify-items: center;
  }
  &-item {
    padding: 45px 20px;
    border-radius: 15px;
    background-color: var(--white);
    box-shadow: 4px 4px 36px rgba(40, 78, 153, 0.15);
    max-width: 325px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    img {
      width: 35px;
      height: 35px;
      object-fit: cover;
      margin-right: 20px;
    }
    i {
      font-size: 33px;
      color: var(--btn-purple-color);
      margin-right: 20px;
    }
    &__title {
      font-weight: 600;
    }
    &:hover {
      .tracks-hint {
        visibility: unset;
        opacity: 1;
      }
    }
  }
  &-hint {
    position: absolute;
    border-radius: 15px;
    color: var(--white);
    background-color: var(--btn-blue-color1);
    padding: 20px 30px;
    max-width: 570px;
    box-shadow: 2px 3px 20px rgba(41, 41, 41, 0.1);
    transition: all 0.3s ease-in-out;
    bottom: 40%;
    left: -20%;
    right: -20%;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    &__title {
      font-size: 22px;
      line-height: 27px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    &__text {
      font-size: 20px;
      line-height: 25px;
    }
  }
  @include adaptive(desktop-mid) {
    &-hint {
      max-width: unset;
      width: 100%;
      left: 0;
      bottom: -20%;
      &__title {
        font-size: 18px;
        line-height: 21px;
      }
      &__text {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  @include adaptive(tablet-big) {
    &-hint {
      display: none;
    }
  }
  @include adaptive(phone) {
    &__list {
      gap: 10px;
    }
    &-item {
      max-height: 70px;
      width: 100%;
      max-width: unset;
      padding: 25px;
      justify-content: flex-start;
      gap: 10px;
      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        margin-right: 10px;
      }
      &__title {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
</style>
