<template>
  <div class="video">
    <div class="video__img">
      <img
        :src="video?.main_image ?? require('@/assets/img/header-title.webp')"
        alt=""
      />
      <button @click="openPopup(video.url)"><Icon :type="'play'" /></button>
    </div>
    <div class="video__text">
      <p>{{ video?.title }}</p>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Video",
  components: { Icon },
  props: ["video"],
  methods: {
    openPopup(item) {
      this.$store.commit("setCurrentPopup", {
        name: "VideoPopup",
        isShown: true,
        props: {
          popupClass: "popup-video",
          video: item,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.video {
  max-width: 450px;
  background-color: var(--white);
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 6px rgba(41, 41, 41, 0.05);
  &__img {
    position: relative;
    height: 210px;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      pointer-events: none;
    }
    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    button {
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      font-size: 70px;
      color: var(--white);
      &:hover {
        -webkit-text-stroke-width: 1px;
      }
    }
  }

  &__text {
    padding: 15px;
    p {
      text-transform: uppercase;
      font-size: 30px;
      line-height: 45px;
      font-weight: 700;
    }
    span {
      font-size: 16px;
      line-height: 23px;
    }
  }
  @include adaptive(tablet-small) {
    &__img {
      height: 150px;
      button {
        font-size: 40px;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
      }
    }
    &__text {
      p {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  @include adaptive(phone) {
    &__img {
      height: 120px;
    }
  }
}
</style>
