<template>
  <div class="faq" itemscope itemtype="https://schema.org/FAQPage">
    <div class="container">
      <div class="faq__content">
        <h2>Ответы на частозадаваемые вопросы</h2>
        <div class="faq__list">
          <div
            class="faq-item"
            :class="{ 'faq-item--active': item.isOpen }"
            v-for="(item, index) in firstArray"
            :key="index"
            itemscope
            itemprop="mainEntity"
            itemtype="https://schema.org/Question"
          >
            <div class="faq-item__q" @click="item.isOpen = !item.isOpen">
              <span itemprop="name">{{ item.question }}</span>
            </div>
            <SmoothHeight>
              <div
                class="faq-item__a"
                v-show="item.isOpen"
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">{{ item.answer }}</div>
              </div>
            </SmoothHeight>
          </div>
          <SmoothHeight class="faq__list faq__list--show">
            <div
              class="faq-item"
              :class="{ 'faq-item--active': item.isOpen }"
              v-for="(item, index) in secondArray"
              :key="index"
              v-show="isOpenList"
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <div class="faq-item__q" @click="item.isOpen = !item.isOpen">
                <span itemprop="name">{{ item.question }}</span>
              </div>
              <SmoothHeight>
                <div
                  class="faq-item__a"
                  v-show="item.isOpen"
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                >
                  <div v-html="item.answer" itemprop="text"></div>
                </div>
              </SmoothHeight>
            </div>
          </SmoothHeight>
        </div>
        <div class="faq__btns">
          <Button @click="isOpenList = true" v-if="isOpenList === false">
            ВСЕ ВОПРОСЫ
          </Button>
          <Button @click="isOpenList = false" v-else>Свернуть</Button>
          <a
            href="https://lk.ligastudentov.com/"
            class="btn btn--red"
            target="_blank"
          >
            ПОДАТЬ ЗАЯВКУ
          </a>
          <!--          <Button-->
          <!--            red-->
          <!--            @click="toLk"-->
          <!--            :disabled="$route.name === 'Shift' && !isShiftActive"-->
          <!--          >-->
          <!--            <span v-if="isShiftActive && $route.name === 'Shift'">-->
          <!--              ПОДАТЬ ЗАЯВКУ-->
          <!--            </span>-->
          <!--            <span v-else>ПОДАЧА ЗАКРЫТА</span>-->
          <!--          </Button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import SmoothHeight from "@/components/Blocks/SmoothHeight";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
export default {
  name: "HomeFAQ",
  components: { SmoothHeight, Button },
  data() {
    return {
      isOpen: false,
      isOpenList: false,
      info: [],
    };
  },
  props: {
    isShiftActive: Boolean,
  },
  computed: {
    firstArray() {
      return this.info.slice(0, 3);
    },
    secondArray() {
      return this.info.slice(3);
    },
  },
  methods: {
    toLk() {
      if (this.$store.state.user.isAuthenticated === false) {
        this.$store.commit("setCurrentPopup", {
          name: "AuthPopup",
          isShown: true,
          props: {
            popupClass: "auth-popup",
          },
        });
      } else {
        this.$router.push({ name: "LkAbout" });
      }
    },
  },
  beforeMount() {
    if (!this.$store.state.storage.storageFAQData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "faq",
        },
      }).then((result) => {
        this.$store.commit("setFAQData", result.data);
        this.info = this.$store.state.storage.storageFAQData;
      });
    } else this.info = this.$store.state.storage.storageFAQData;
  },
};
</script>

<style lang="scss">
.faq {
  margin-top: 80px;
  margin-bottom: 50px;
  &__content {
    h2 {
      margin-bottom: 30px;
    }
  }
  &__list {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &--show {
      margin-bottom: 0;
    }
  }
  &-item {
    &__q {
      font-weight: 600;
      margin-bottom: 10px;
      cursor: pointer;
      display: inline-block;
      white-space: pre-line;
      &::before {
        content: "\271A";
        margin-right: 5px;
        transition: all 0.3s ease;
      }
    }
    &__a {
      margin-left: 15px;
      max-width: 920px;
      white-space: pre-line;
    }
    &--active {
      .faq-item__q {
        &::before {
          content: "\2212";
        }
      }
    }
  }
  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
  @include adaptive(phone) {
    &__list {
      margin-bottom: 30px;
    }
    &__btns {
      gap: 50px;
    }
  }
}
</style>
